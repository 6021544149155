import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  NewHomesHero,
  SinglePropertySimilarSlider,
  NewhomesContact,
  RentStepByStep,
  NewHomesGetAdvice,
} from 'components/sections';
import { NewHomesHomeBadge } from 'components/ui';
import { NewHomesContactForm, TripleImageSection } from 'components/blocks';
import newHomesPageMotion from 'motion/new-homes.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionAboutContact, functionSlider, functionGetAdvice } from 'motion/new-homes.js';
import { tripleImageSectionScroll } from 'motion/buy.js';
import { functionTimeline } from 'motion/rent.js';


const mockData = {

  similarPropertiesText: {
    title: 'New Developments',
    description: '',
  },
  contactCta: {
    left: {
      title: 'Join the team',
      content: 'Do you think you have what it takes to be part of our award winning team?',
    },
    right: {
      title: 'Refer a friend',
      content: 'Receive a reward for referring your friends and family members to our trusted property managers',
    },
  },
}

const NewHomesPage = ({ data }) => {

    const  img1 = {src:data.pages.newhomes.highlight.img1, height:314, width: 456};
    const  img2 = {src:data.pages.newhomes.highlight.img2, height:386, width: 346};
    const  img3 = {src:data.pages.newhomes.highlight.img3, height:649, width: 416};

    const  {seo} = data.pages.newhomes;

    data.pages.newhomes.highlight.fields = {
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Telephone',
      email: 'E-mail',
      price: 'Original purchase price',
      plotNumber: 'Plot number',
      address: 'Development, Address',
      moreInfo: 'Any additional information',
      gdprCheckbox: 'Agree to GDPR',
      submit: 'Submit',
    };

    data.pages.newhomes.highlight.errorMessage = 'There was an error sending your form.';

  return(
  <Layout onVisible={newHomesPageMotion}>
    <SEO title={seo.title} description={seo.meta} keywords={seo.keywords} />

    <NewHomesHero {...data.pages.newhomes.landing} />

    <ViewportEnterAnimation offset={100} animation={tripleImageSectionScroll}>
      <TripleImageSection
        imageBack={img1}
        imageMiddle={img2}
        imageFront={img3}

        imageBackalt={data.pages.newhomes.highlight.img1alt}
        imageMiddlealt={data.pages.newhomes.highlight.img2alt}                     
        imageFrontalt={data.pages.newhomes.highlight.img3alt}
      >
        <NewHomesContactForm {...data.pages.newhomes.highlight} />
      </TripleImageSection>
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionSlider}>
      <SinglePropertySimilarSlider
        content={mockData.similarPropertiesText}
      />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionTimeline}>
      <RentStepByStep {...data.pages.newhomes.stepsData} />
    </ViewportEnterAnimation>

    <NewHomesHomeBadge />
    <ViewportEnterAnimation offset={100} animation={functionGetAdvice}>
      <NewHomesGetAdvice {...data.pages.newhomes.advice} />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionAboutContact}>
      <NewhomesContact content={data.pages.newhomes.contactFooter} />
    </ViewportEnterAnimation>
  </Layout>
)
}

export const query = graphql`
  query NewHomes {
    pages {
      propertySearchForm {
        title
        offerTypes {
          lettings
          sales
        }
        defaultCost {
          min
          max
        }
        form {
          labels {
            area
            apartment
            bedrooms
            includeNewHomes
            searchBtn
          }
          selects {
            apartment {
              value
              text
            }
            bedrooms {
              value
              text
            }
          }
        }
      }
      newhomes {
        landing {
          title
          text
          buttonText
          badges{
            newHomesLabel
            awardWinning
            fiveStarReviews
          }
        }
        highlight {
          title
          description
          img1
          img2
          img3
          img1alt
          img2alt
          img3alt
        }
        stepsData {
          title
          steps {
            title
            content
          }
        }
        advice {
          title
          content
          buttonText
        }
        contactFooter{
          join{
            img
            title
            description
          }
          refer{
            img
            title
            description
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;

export default NewHomesPage;
